import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PeterImage from '../images/team/peter.jpg'
import RichieImage from '../images/team/richie.jpg'

const About = () => (
  <Layout>
    <Seo title="About" />
    <div className="About SubPage">
      <div className="Subpage__header">
        <h1>About Us</h1>
      </div>
      <div className="About__content">
        <div className="maxWidthText">
          <p>Nitor Fitness is an up and coming software company dedicated to providing top quality fitness software. We aim to provide a variety of fitness tools and workout aides to help people achieve their fitness goals, whatever those may be.</p>
          <p>Our goal is to provide a wide range of workout tools while falling back onto the science behind it. We want to cut through all the bad advice and uninformed answers on the internet to provide people with real, actionable fitness software that helps them achieve their long term fitness goals.</p>
        </div>
      </div>
      <div className="About__team flex-row maxWidth">
        <div className="About__team__member Card">
          <img src={PeterImage} width="200px" />
          <h3>Peter Jewicz - Lead Engineer</h3>
          <p>Peter is a software developer with over a decade of experience. He’s worked on a wide variety of projects from gaming to finance, but has always had a deep interest in fitness and healthy lifestyles. He brings this passion to Nitor Fitness to help build and maintain their applications and tech infrastructure.</p>
        </div>
        <div className="About__team__member Card">
          <img src={RichieImage} width="200px" />
          <h3>Richie Teresi - Director Of Fitness</h3>
          <p>Richie Teresi is a personal trainer with a deep love for fitness and the science behind it. He holds a B.S degree in Biology and brings that scientific thought and scrutiny to his workout and exercise plans. He takes a scientific approach to fitness, and hopes to cut down all the “Bro Science” that has taken over the internet.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default About
